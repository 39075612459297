import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { TravelPeriodFragmentDoc } from 'graphql/searchControl/TravelPeriod.generated';
import { DestinationFragmentDoc } from 'graphql/destination/Destination.generated';
export type HistorySrlRecordFragment = {
  __typename?: 'HistorySrlRecord';
  rooms: number;
  travelType: Types.TravelType;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  period: {
    __typename?: 'TravelPeriodComponent';
    type: Types.SearchPeriodType;
    exactPeriod: {
      __typename?: 'ExactTravelPeriod';
      departureDate: string;
      returnDate: string;
    };
    flexiblePeriod: {
      __typename?: 'FlexibleTravelPeriod';
      departureDate: string;
      maxDuration: number;
      minDuration: number;
      returnDate: string;
    };
  };
  travellers: {
    __typename?: 'Travellers';
    adults: number;
    childrenDobs?: Array<string> | null;
  };
  destinations?: Array<{
    __typename?: 'TravelDestination';
    id: string;
    name: string;
    type: Types.TravelDestinationType;
    mythosCode?: string | null;
    location?: {
      __typename?: 'GeoLocation';
      airport?: {
        __typename?: 'Airport';
        iata?: string | null;
        name?: string | null;
      } | null;
      continent?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      countryGroup?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      country?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      region?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      destination?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
      resort?: {
        __typename?: 'GeoObject';
        id: string;
        name: string;
        type: Types.GeoType;
      } | null;
    } | null;
  }> | null;
};

export const HistorySrlRecordFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'historySrlRecord' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HistorySrlRecord' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rooms' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'period' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TravelPeriod' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travellers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childrenDobs' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destinations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'destination' },
                },
              ],
            },
          },
        ],
      },
    },
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...TravelPeriodFragmentDoc.definitions,
    ...DestinationFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
