import { mapSearchControlTravelRoomsToFormTravelRooms } from '@hotelplan/components.common.travel-rooms';
import type {
  FlightSrlFilterCriteriaInput,
  FlightOffer,
} from '@hotelplan/graphql.types';
import type {
  FlightOfferMetaData,
  IRunSearchData,
} from '@hotelplan/libs.tracking';
import {
  mapFiltersStateToFilterCriteriaInput,
  mapFiltersStateToTrackableData,
} from 'components/domain/filters/Filters.mappers';
import type { IFiltersFormState } from 'components/domain/filters/Filters.types';
import { mapSearchControlFlightPartitionsToFormFlightPartitions } from 'components/domain/flightPartitions/FlightPartitions.mappers';
import { prepareFlightPartitionsToFormState } from 'components/domain/flightPartitions/FlightPartitions.utils';
import { mapSearchControlFlightTypeToFormFlightType } from 'components/domain/flightTypes/FlightTypes.mappers';
import {
  getSearchControlValuesToFormStateMapper,
  mapSearchControlFormStateToTrackableData,
} from 'components/domain/searchControl/SearchControl.mappers';
import type { IFlightSearchControlFormState } from 'components/domain/searchControl/SearchControl.types';
import type { FlightSearchControlFragment } from 'graphql/flight/FlightSearchControl.generated';
import type { IFlightSRLState } from './FlightSRL.types';

const filtersFormStateKeyToFlightSrlFilterCriteriaInputKey: {
  [K in keyof IFiltersFormState]: keyof FlightSrlFilterCriteriaInput;
} = {
  directFlightArrivalTime: 'directFlightArrivalTime',
  directFlightDepartureTime: 'directFlightDepartureTime',
  flightAirlines: 'flightAirlines',
  flightStopOver: 'flightStopOver',
  maxPricePerPerson: 'maxPricePerPerson',
  returnFlightArrivalTime: 'returnFlightArrivalTime',
  returnFlightDepartureTime: 'returnFlightDepartureTime',
};

export const mapFlightSRLSearchControlValuesToFormState = getSearchControlValuesToFormStateMapper<
  FlightSearchControlFragment | undefined,
  IFlightSearchControlFormState
>((searchControl: FlightSearchControlFragment | undefined) => {
  if (!searchControl) return null;

  const flightPartitions = prepareFlightPartitionsToFormState(
    searchControl.flightPartitions,
    searchControl.flightType
  );

  return {
    travelType: searchControl.travelType,
    flightType: mapSearchControlFlightTypeToFormFlightType(
      searchControl.flightType
    ),
    travelRooms: mapSearchControlTravelRoomsToFormTravelRooms(
      searchControl.travellers,
      0 // NOTE: Set rooms to 0 to get rid of rooms selection on the Flight Search Control
    ),
    flightPartitions: mapSearchControlFlightPartitionsToFormFlightPartitions(
      flightPartitions
    ),
  };
});

export const mapFlightSrlFormFilterValuesToFlightSrlFilterCriteriaInput = (
  filterState: IFiltersFormState | null | undefined
): FlightSrlFilterCriteriaInput => {
  const criteria = mapFiltersStateToFilterCriteriaInput(
    filterState,
    filtersFormStateKeyToFlightSrlFilterCriteriaInputKey
  ) as FlightSrlFilterCriteriaInput;

  criteria.maxFlightStopOverDuration =
    filterState?.flightStopOverDuration?.maxSelected;
  criteria.minFlightStopOverDuration =
    filterState?.flightStopOverDuration?.minSelected;

  return criteria;
};

export const mapFlightSRLStateToTrackableData = (
  state: IFlightSRLState
): IRunSearchData => {
  return {
    searchControl: mapSearchControlFormStateToTrackableData(
      state.searchControl
    ),
    filters: mapFiltersStateToTrackableData(state.filters),
  };
};

export const mapFlightOfferItemToTrackableData = (
  offer: FlightOffer
): FlightOfferMetaData => {
  return {
    id: offer.offerId,
    price: offer.pricePerPerson.amount,
  };
};
