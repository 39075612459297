import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import type {
  HistoryFlightRecord,
  HistoryProductRecord,
  HistorySrlRecord,
} from '@hotelplan/graphql.types';
import { getRouterLink } from '@hotelplan/libs.router-link-utils';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { mapFlightSRLSearchControlValuesToFormState } from 'components/domain/flightSRL/FlightSRL.mappers';
import type { ISearchControlState } from 'components/domain/searchControl/SearchControl.types';
import { mapSRLSearchControlValuesToFormState } from 'components/domain/srl/SRL.mappers';
import { HistoryType } from './SearchHistory.types';
import {
  getIconName,
  getTitleFromHistorySearchRecord,
  getTitleFromHistoryProductRecord,
  getSubTitleFromHistorySearchRecord,
  getSubTitleFromHistoryProductRecord,
  DEFAULT_ICON_NAME,
} from './SearchHistory.utils';

export interface ISearchHistoryGroupProps {
  header?: string;
  historyType: HistoryType;
  items?: (HistoryProductRecord | HistorySrlRecord | HistoryFlightRecord)[];
  onHistoryItemClick?: (searchState?: ISearchControlState) => void;
  testId?: string;
  useDefaultIcon?: boolean;
  disableIcon?: boolean;
  onNextPage?(): void;
}

const SearchHistoryGroupWrapper = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    marginBottom: [5, 6],
    '.search-history-group-title': {
      ...FONT_SIZE.S,
      marginBottom: [3, 4],
    },
  })
);

const LinkWrapper = styled(Link)(({ theme: { colors } }) =>
  sx2CssThemeFn({
    marginBottom: [3, 4],
    display: 'flex',
    ':hover': {
      color: colors.interactionPrimary,
      '.icon': {
        color: colors.interactionPrimary,
      },
    },
  })
);

const IconWrapper = styled(Icon)<{ vertical: boolean }>(
  sx2CssThemeFn({
    mt: 1,
    mr: [3, 4],
    width: '45px',
    height: '24px',
  }),
  ({ vertical }) => (vertical ? { marginTop: '8px', height: '60px' } : {})
);

const LinkItemWrapper = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    width: ['calc(100% - 45px - 16px)', 'calc(100% - 45px - 24px)'],
    '.subtitle': {
      ...FONT_SIZE.MEDIUM,
      color: 'darkGray',
    },
  })
);

const LinkButtonWrapper = styled.div.attrs({
  className: 'search-history-paginator',
})(({ theme: { colors, space, FONT_SIZE } }) => ({
  display: 'flex',
  borderBottom: '1px solid',
  borderColor: colors.lightGreyFourth,
  paddingBottom: space[3],
  '.search-history-button': {
    ...FONT_SIZE.LARGE,
    marginLeft: 'auto',
    paddingBottom: space[3],
    transform: 'translate(0)',
    '.icon': {
      width: space[3],
      height: space[3],
      marginLeft: space[2],
      '&:hover,&:focus': {
        color: colors.defaultText,
      },
    },
  },
}));

const SearchHistoryGroup: React.FC<ISearchHistoryGroupProps> = ({
  items = [],
  header,
  historyType,
  onHistoryItemClick,
  testId,
  useDefaultIcon,
  disableIcon,
  onNextPage,
}) => {
  const [t] = useTranslation(['common', 'search']);

  return (
    <SearchHistoryGroupWrapper className="historyGroup" data-id={testId}>
      {header && <h3 className="search-history-group-title">{header}</h3>}
      {items.length === 0 && (
        <>
          {historyType === HistoryType.PRODUCT && t('history.products.empty')}
          {historyType === HistoryType.SEARCH && t('history.searches.empty')}
        </>
      )}
      {items.map((historyRecord, i) => {
        let title = '';
        let subtitle = '';
        let icon = DEFAULT_ICON_NAME;
        let onClick = onHistoryItemClick;

        switch (historyRecord.__typename) {
          case 'HistorySrlRecord':
          case 'HistoryFlightRecord':
            icon = getIconName(
              historyRecord.travelType,
              undefined,
              useDefaultIcon
            );
            title = getTitleFromHistorySearchRecord(historyRecord, t);
            subtitle = getSubTitleFromHistorySearchRecord(historyRecord, t);
            onClick = () => {
              onHistoryItemClick &&
                onHistoryItemClick(
                  historyRecord.__typename === 'HistorySrlRecord'
                    ? {
                        ...mapSRLSearchControlValuesToFormState(historyRecord),
                        type: 'HOTEL',
                      }
                    : {
                        ...mapFlightSRLSearchControlValuesToFormState(
                          historyRecord
                        ),
                        type: 'FLIGHT',
                      }
                );
            };

            break;
          case 'HistoryProductRecord':
            icon = getIconName(
              historyRecord.travelType,
              historyRecord.productCase,
              useDefaultIcon
            );
            title = getTitleFromHistoryProductRecord(historyRecord);
            subtitle = getSubTitleFromHistoryProductRecord(historyRecord);

            break;
          default:
            break;
        }

        return (
          <RouterLink
            key={JSON.stringify({ i, subtitle, title })}
            {...getRouterLink(historyRecord.link)}
            passHref
          >
            <LinkWrapper
              onClick={() => {
                onClick && onClick();
              }}
            >
              {!disableIcon && (
                <IconWrapper name={icon} vertical={icon.includes('vertical')} />
              )}
              <LinkItemWrapper>
                <div>{title}</div>
                <div className="subtitle">{subtitle}</div>
              </LinkItemWrapper>
            </LinkWrapper>
          </RouterLink>
        );
      })}
      <LinkButtonWrapper>
        {onNextPage ? (
          <BsButton
            variant={E_BUTTON_TYPE.LINK_BUTTON}
            className="search-history-button"
            data-id="load-more-button"
            onClick={onNextPage}
          >
            {t('common:load.more')}
            <Icon name="chevron-down" />
          </BsButton>
        ) : null}
      </LinkButtonWrapper>
    </SearchHistoryGroupWrapper>
  );
};

export default SearchHistoryGroup;
