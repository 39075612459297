import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { HistoryFlightRecordFragmentDoc } from './HistoryFlightRecordFragment.generated';
import { HistorySrlRecordFragmentDoc } from './HistorySrlRecordFragment.generated';
import {
  PageFragmentFragmentDoc,
  GeoItemFragmentDoc,
  ProductItemFragmentDoc,
} from 'graphql/srl/SRLFragments.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetRecentSearchesHistoryQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
  pageNumber: Types.Scalars['Int'];
}>;

export type GetRecentSearchesHistoryQuery = {
  __typename?: 'Query';
  history: {
    __typename?: 'HistoryContext';
    recentSearches: {
      __typename?: 'HistoryUnionResponse';
      recordUnions?: Array<
        | {
            __typename?: 'HistoryFlightRecord';
            travelType: Types.TravelType;
            flightType: Types.FlightType;
            link: {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            };
            travellers: {
              __typename?: 'Travellers';
              adults: number;
              childrenDobs?: Array<string> | null;
            };
            flightPartitions?: Array<{
              __typename?: 'FlightPartition';
              flightClass: Types.FlightClass;
              departureAirport?: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              } | null;
              arrivalAirport?: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              } | null;
              travelPeriod: {
                __typename?: 'ExactTravelPeriod';
                returnDate: string;
                departureDate: string;
              };
            }> | null;
          }
        | {
            __typename?: 'HistorySrlRecord';
            rooms: number;
            travelType: Types.TravelType;
            link: {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            };
            period: {
              __typename?: 'TravelPeriodComponent';
              type: Types.SearchPeriodType;
              exactPeriod: {
                __typename?: 'ExactTravelPeriod';
                departureDate: string;
                returnDate: string;
              };
              flexiblePeriod: {
                __typename?: 'FlexibleTravelPeriod';
                departureDate: string;
                maxDuration: number;
                minDuration: number;
                returnDate: string;
              };
            };
            travellers: {
              __typename?: 'Travellers';
              adults: number;
              childrenDobs?: Array<string> | null;
            };
            destinations?: Array<{
              __typename?: 'TravelDestination';
              id: string;
              name: string;
              type: Types.TravelDestinationType;
              mythosCode?: string | null;
              location?: {
                __typename?: 'GeoLocation';
                airport?: {
                  __typename?: 'Airport';
                  iata?: string | null;
                  name?: string | null;
                } | null;
                continent?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
                countryGroup?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
                country?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
                region?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
                destination?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
                resort?: {
                  __typename?: 'GeoObject';
                  id: string;
                  name: string;
                  type: Types.GeoType;
                } | null;
              } | null;
            }> | null;
          }
      > | null;
      page: {
        __typename?: 'Page';
        pageNumber: number;
        resultsTotal?: number | null;
        resultsPerPage?: number | null;
      };
    };
  };
};

export type HistoryUnionResponseFragment = {
  __typename?: 'HistoryUnionResponse';
  recordUnions?: Array<
    | {
        __typename?: 'HistoryFlightRecord';
        travelType: Types.TravelType;
        flightType: Types.FlightType;
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
        travellers: {
          __typename?: 'Travellers';
          adults: number;
          childrenDobs?: Array<string> | null;
        };
        flightPartitions?: Array<{
          __typename?: 'FlightPartition';
          flightClass: Types.FlightClass;
          departureAirport?: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          } | null;
          arrivalAirport?: {
            __typename?: 'Airport';
            iata?: string | null;
            name?: string | null;
          } | null;
          travelPeriod: {
            __typename?: 'ExactTravelPeriod';
            returnDate: string;
            departureDate: string;
          };
        }> | null;
      }
    | {
        __typename?: 'HistorySrlRecord';
        rooms: number;
        travelType: Types.TravelType;
        link: {
          __typename?: 'InternalLink';
          caption?: string | null;
          uri: string;
          targetPageType: Types.PageType;
        };
        period: {
          __typename?: 'TravelPeriodComponent';
          type: Types.SearchPeriodType;
          exactPeriod: {
            __typename?: 'ExactTravelPeriod';
            departureDate: string;
            returnDate: string;
          };
          flexiblePeriod: {
            __typename?: 'FlexibleTravelPeriod';
            departureDate: string;
            maxDuration: number;
            minDuration: number;
            returnDate: string;
          };
        };
        travellers: {
          __typename?: 'Travellers';
          adults: number;
          childrenDobs?: Array<string> | null;
        };
        destinations?: Array<{
          __typename?: 'TravelDestination';
          id: string;
          name: string;
          type: Types.TravelDestinationType;
          mythosCode?: string | null;
          location?: {
            __typename?: 'GeoLocation';
            airport?: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            } | null;
            continent?: {
              __typename?: 'GeoObject';
              id: string;
              name: string;
              type: Types.GeoType;
            } | null;
            countryGroup?: {
              __typename?: 'GeoObject';
              id: string;
              name: string;
              type: Types.GeoType;
            } | null;
            country?: {
              __typename?: 'GeoObject';
              id: string;
              name: string;
              type: Types.GeoType;
            } | null;
            region?: {
              __typename?: 'GeoObject';
              id: string;
              name: string;
              type: Types.GeoType;
            } | null;
            destination?: {
              __typename?: 'GeoObject';
              id: string;
              name: string;
              type: Types.GeoType;
            } | null;
            resort?: {
              __typename?: 'GeoObject';
              id: string;
              name: string;
              type: Types.GeoType;
            } | null;
          } | null;
        }> | null;
      }
  > | null;
  page: {
    __typename?: 'Page';
    pageNumber: number;
    resultsTotal?: number | null;
    resultsPerPage?: number | null;
  };
};

export const HistoryUnionResponseFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'historyUnionResponse' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HistoryUnionResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recordUnions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'historyFlightRecord' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'historySrlRecord' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'pageFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...HistoryFlightRecordFragmentDoc.definitions,
    ...HistorySrlRecordFragmentDoc.definitions,
    ...PageFragmentFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
export const GetRecentSearchesHistoryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRecentSearchesHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pageNumber' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'history' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'recentSearches' },
                  name: { kind: 'Name', value: 'recentSearchesWithPage' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'pageNumber' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resultsPerPage' },
                            value: { kind: 'IntValue', value: '5' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'historyUnionResponse' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...HistoryUnionResponseFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetRecentSearchesHistoryQuery__
 *
 * To run a query within a React component, call `useGetRecentSearchesHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentSearchesHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentSearchesHistoryQuery({
 *   variables: {
 *      context: // value for 'context'
 *      pageNumber: // value for 'pageNumber'
 *   },
 * });
 */
export function useGetRecentSearchesHistoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRecentSearchesHistoryQuery,
    GetRecentSearchesHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetRecentSearchesHistoryQuery,
    GetRecentSearchesHistoryQueryVariables
  >(GetRecentSearchesHistoryDocument, options);
}
export function useGetRecentSearchesHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRecentSearchesHistoryQuery,
    GetRecentSearchesHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetRecentSearchesHistoryQuery,
    GetRecentSearchesHistoryQueryVariables
  >(GetRecentSearchesHistoryDocument, options);
}
export type GetRecentSearchesHistoryQueryHookResult = ReturnType<
  typeof useGetRecentSearchesHistoryQuery
>;
export type GetRecentSearchesHistoryLazyQueryHookResult = ReturnType<
  typeof useGetRecentSearchesHistoryLazyQuery
>;
export type GetRecentSearchesHistoryQueryResult = Apollo.QueryResult<
  GetRecentSearchesHistoryQuery,
  GetRecentSearchesHistoryQueryVariables
>;
