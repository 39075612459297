import { TFunction } from 'next-i18next';
import { mapSearchControlTravelPeriodToFormTravelDates } from '@hotelplan/components.common.travel-dates';
import { getShortInformation as formatTravelDates } from '@hotelplan/components.common.travel-dates';
import {
  mapSearchControlTravelRoomsToFormTravelRooms,
  getShortInformation as formatTravelRooms,
} from '@hotelplan/components.common.travel-rooms';
import {
  TravelType,
  FlightType,
  TravelDestination,
} from '@hotelplan/graphql.types';
import type {
  HistoryFlightRecord,
  HistoryProductRecord,
  HistorySrlRecord,
} from '@hotelplan/graphql.types';
import { mapSearchControlFlightPartitionsToFormFlightPartitions } from 'components/domain/flightPartitions/FlightPartitions.mappers';
import { mapTravelTypeToTravelTypeData } from 'components/domain/travelTypes/TravelTypes.mappers';

interface ITravelTypeToIconName {
  [key: string]: string | undefined;
}

export const DEFAULT_ICON_NAME = 'history';
export const TRAVEL_TYPE_TO_ICON_NAME: ITravelTypeToIconName = {
  // NOTE: Travel type icons.
  HOTEL: 'hotel',
  FLIGHT: 'flug',
  PACKAGE: 'flughotel-vertical',
  // NOTE: Product case icons.
  SHIP: 'cruise',
  ROUNDTRIP: 'rundreisen',
  // NOTE: Product package icons.
  SHIP_PACKAGE: 'flugcruise-vertical',
  HOTEL_PACKAGE: 'flughotel-vertical',
  ROUNDTRIP_PACKAGE: 'flugroundtrip-vertical',
};

export const getFormattedLocationFromDestination = (
  destination: TravelDestination
): string => {
  const locationParts: string[] = [];
  const location = destination.location;

  if (location?.country) {
    locationParts.push(location.country.name);
  }
  if (location?.destination) {
    locationParts.push(location.destination.name);
  }
  if (location?.airport?.name) {
    locationParts.push(location.airport.name);
  }

  return locationParts.join(', ');
};

export const getIconName = (
  travelType?: TravelType | null,
  productCase?: string,
  useDefaultIcon?: boolean
): string => {
  if (useDefaultIcon) {
    return DEFAULT_ICON_NAME;
  }

  let iconName;

  if (productCase) {
    iconName =
      travelType === TravelType.Package
        ? TRAVEL_TYPE_TO_ICON_NAME[`${productCase}_${travelType}`]
        : TRAVEL_TYPE_TO_ICON_NAME[productCase];
  } else {
    iconName = travelType
      ? TRAVEL_TYPE_TO_ICON_NAME[travelType]
      : DEFAULT_ICON_NAME;
  }

  return iconName || DEFAULT_ICON_NAME;
};

export const getTitleFromHistorySearchRecord = (
  historyRecord: HistorySrlRecord | HistoryFlightRecord,
  t: TFunction
): string => {
  // NOTE: Prepare travel type to the specified format.
  const formattedTravelType = t(
    mapTravelTypeToTravelTypeData(historyRecord.travelType).label
  );

  if (historyRecord.__typename === 'HistorySrlRecord') {
    let formattedDestinations: string | undefined = undefined;

    if (historyRecord.destinations && historyRecord.destinations.length > 0) {
      formattedDestinations =
        historyRecord.destinations.length > 1
          ? historyRecord.destinations[0].name.concat(', ', '...')
          : historyRecord.destinations[0].name;
    }

    return `${
      formattedDestinations || t('common:history.searches.noDestinations')
    }, ${formattedTravelType}`;
  }

  if (historyRecord.__typename === 'HistoryFlightRecord') {
    const flightPartitions = mapSearchControlFlightPartitionsToFormFlightPartitions(
      historyRecord.flightPartitions
    );

    let formattedAirports = '';
    const firstPartitionDepartureAirport = flightPartitions
      ? flightPartitions[0].departureAirport?.name
      : '';
    const firstPartitionArrivalAirport = flightPartitions
      ? flightPartitions[0].arrivalAirport?.name
      : '';

    formattedAirports = `${firstPartitionDepartureAirport} - ${firstPartitionArrivalAirport}`;

    if (historyRecord.flightType === FlightType.OpenJaw) {
      const secondPartitionDepartureAirport = flightPartitions
        ? flightPartitions[1].departureAirport?.name
        : '';
      const secondPartitionArrivalAirport = flightPartitions
        ? flightPartitions[1].arrivalAirport?.name
        : '';

      formattedAirports = `${formattedAirports}${
        secondPartitionDepartureAirport === firstPartitionArrivalAirport
          ? ` - ${secondPartitionArrivalAirport}`
          : ` / ${secondPartitionDepartureAirport} - ${secondPartitionArrivalAirport}`
      }`;
    }

    return `${formattedAirports}, ${formattedTravelType}`;
  }

  return '';
};

export const getSubTitleFromHistorySearchRecord = (
  historyRecord: HistorySrlRecord | HistoryFlightRecord,
  t: TFunction
): string => {
  // NOTE: Prepare travellers/rooms to the specified format.
  const rooms =
    historyRecord.__typename === 'HistorySrlRecord' ? historyRecord.rooms : 0;
  const travellers = mapSearchControlTravelRoomsToFormTravelRooms(
    historyRecord.travellers,
    rooms
  );
  const formattedTravellers = formatTravelRooms(t, travellers);

  if (historyRecord.__typename === 'HistorySrlRecord') {
    const travelDates = mapSearchControlTravelPeriodToFormTravelDates(
      historyRecord.period
    );

    const formattedTravelDates = formatTravelDates(t, {
      departureDate: travelDates.departureDate,
      returnDate: travelDates.returnDate,
      durationData: travelDates.duration,
      showDuration: true,
    }).travelDates;

    return `${formattedTravelDates}, ${formattedTravellers}`;
  }

  if (historyRecord.__typename === 'HistoryFlightRecord') {
    const flightPartitions = mapSearchControlFlightPartitionsToFormFlightPartitions(
      historyRecord.flightPartitions
    );

    let formattedTravelDates = '';

    // NOTE: If the flight type is not OpenJaw, then we have only one partition.
    if (historyRecord.flightType !== FlightType.OpenJaw) {
      const travelDates = flightPartitions
        ? flightPartitions[0].travelDates
        : null;

      formattedTravelDates = travelDates
        ? formatTravelDates(t, {
            departureDate: travelDates.departureDate,
            returnDate: travelDates.returnDate,
            durationData: travelDates.duration,
            showDuration: true,
          }).travelDates
        : '';
    } else {
      const firstPartitionTravelDates = flightPartitions
        ? flightPartitions[0].travelDates
        : null;
      const secondPartitionTravelDates = flightPartitions
        ? flightPartitions[1].travelDates
        : null;

      if (!firstPartitionTravelDates || !secondPartitionTravelDates) {
        return '';
      }

      formattedTravelDates = formatTravelDates(t, {
        departureDate: firstPartitionTravelDates.departureDate,
        returnDate: secondPartitionTravelDates.returnDate,
        durationData: firstPartitionTravelDates.duration,
        showDuration: false,
      }).travelDates;
    }

    return `${formattedTravelDates}, ${formattedTravellers}`;
  }

  return '';
};

export const getTitleFromHistoryProductRecord = (
  historyRecord: HistoryProductRecord
): string => {
  return historyRecord.destinations ? historyRecord.destinations[0].name : '';
};

export const getSubTitleFromHistoryProductRecord = (
  historyRecord: HistoryProductRecord
): string => {
  const destination = historyRecord.destinations;

  if (!destination || !destination[0].location) {
    return '';
  }

  return getFormattedLocationFromDestination(destination[0]);
};
